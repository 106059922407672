'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, LinkButton } from '@ui/button';
import { Heading } from '@web/atoms';
import { ProductSelector, Footer, LoadingView } from '@web/components';
import { RedirectToLogin } from '../../router';
import { useReporter } from '../../reporter';
import { useSessionCustomer } from '../../customer';
import { AccountHeader } from '../AccountHeader';
import { FOOTER_CONTENT } from '../../testing/constants';
import { StyledEditRecipesFormContainer } from '../EditRecipesFlowContainer/styles';
import { userUpdatesPlanType, userViewMealPlanTypeScreen } from './events';
import {
  ButtonContainer,
  SubtitleHeading,
  Subtext,
  GFWarningText,
} from './styles.js';

export const MealPrepTypeSelectionForm = ({
  onSubmit,
  defaultMealPrepType,
  mealPrepTypes,
  collapsedMealPrepTypes,
  unavailableMealPrepTypes,
  submitButtonText,
  cancelButtonText,
  cancelTarget,
  cancelAction,
  links,
  logoUrl,
  logoSrText,
  headline,
  subheadline,
  hasGFPlan,
  GFWarning,
  isBakedCustomer,
}) => {
  const reporter = useReporter();
  const [selectedMealPrepType, setSelectedMealPrepType] =
    useState(defaultMealPrepType);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  useEffect(() => {
    reporter.tag(userViewMealPlanTypeScreen());
  }, [reporter]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading) {
    return <LoadingView />;
  }
  const onChange = ([mealPrepType]) => {
    setSelectedMealPrepType(mealPrepType);
  };
  const onSubmitHandler = async e => {
    e.preventDefault();
    reporter.tag(
      userUpdatesPlanType({
        previous_meal_prep: defaultMealPrepType,
        new_meal_prep: selectedMealPrepType,
      }),
    );
    setIsSubmitting(true);
    await onSubmit(selectedMealPrepType);
    setIsSubmitting(false);
  };

  const isSaveDisabled = selectedMealPrepType.length === 0 || isSubmitting;

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <StyledEditRecipesFormContainer>
        <form onSubmit={onSubmitHandler}>
          <Heading
            headingLevel="h1"
            typography={{ desktop: 'heading1', mobile: 'heading2' }}
            headingText={headline}
            position="center"
            horizontalPadding={2}
            verticalPadding={1}
          />
          <SubtitleHeading>{subheadline}</SubtitleHeading>
          {hasGFPlan && <GFWarningText>{GFWarning}</GFWarningText>}
          <ProductSelector
            products={mealPrepTypes}
            collapsedProducts={collapsedMealPrepTypes}
            selected={[selectedMealPrepType]}
            unavailableProducts={unavailableMealPrepTypes}
            onChange={onChange}
            variant={'radio'}
          />
          {isBakedCustomer && (
            <Subtext>
              If you switch to another plan, the Gently Baked plan will no
              longer be available
            </Subtext>
          )}
          <ButtonContainer isBakedCustomer={isBakedCustomer}>
            <>
              <Button disabled={isSaveDisabled} type="submit">
                {submitButtonText}
              </Button>
              {cancelAction ? (
                <Button onClick={cancelAction} variant="secondary" fullWidth>
                  {cancelButtonText}
                </Button>
              ) : (
                <LinkButton href={cancelTarget} variant="secondary" fullWidth>
                  {cancelButtonText}
                </LinkButton>
              )}
            </>
          </ButtonContainer>
        </form>
      </StyledEditRecipesFormContainer>
      <Footer {...FOOTER_CONTENT} />
    </>
  );
};
MealPrepTypeSelectionForm.displayName = 'MealPrepTypeSelectionForm';
MealPrepTypeSelectionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultMealPrepType: PropTypes.string.isRequired,
  mealPrepTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
      img: PropTypes.any,
      pricingInfo: PropTypes.shape({
        price: PropTypes.string.isRequired,
        discount: PropTypes.string,
      }),
      badge: PropTypes.string,
    }).isRequired,
  ).isRequired,
  collapsedMealPrepTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
      img: PropTypes.any,
      pricingInfo: PropTypes.shape({
        price: PropTypes.string.isRequired,
        discount: PropTypes.string,
      }),
      badge: PropTypes.string,
    }),
  ),
  unavailableMealPrepTypes: PropTypes.array,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  cancelTarget: PropTypes.string.isRequired,
  cancelAction: PropTypes.func,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
  isBakedCustomer: PropTypes.bool,
  hasGFPlan: PropTypes.bool,
  GFWarning: PropTypes.string.isRequired,
};
